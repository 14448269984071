import { useQuery } from "@tanstack/react-query";
import { subDays } from "date-fns";
import { orderBy, sumBy } from "lodash";
import { HTMLAttributes, ReactNode, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Currency } from "../billing/components";
import {
    affiliateCommissionMultiplier,
    getEstimatedPayoutFromData,
} from "../jason-proof-of-concept/other/actions/getEstimatedPayout";
import { getUsers } from "../jason-proof-of-concept/users/actions/get-users";
import { getDataFromProgressData } from "../jason-proof-of-concept/wizard/wizard-container";
import { getAuthTokenNoThrow } from "../services/auth-header";
import { TBody, THead, Table, Td, Th, Tr } from "../layout/table";
import ButtonNeoGen from "../layout/button-neogen";
import { CompanyStatus } from "../companies/domain/company";
import { timeAgo } from "../sections/users/cards/calls-list";
import { ClearERCUser } from "../typings/api/clear-erc-user";
import { sortUsers } from "../sections/utilities/sortUsers";
import { Select } from "../layout/form/select-input";
import PageDescription from "../layout/page-description";
import { useUserCompanies } from "../companies/hooks/use-user-companies";
import { useCompanies } from "../companies/hooks/use-companies";
import { useProcessflowProgresses } from "../companies/hooks/use-processflow-progresses";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { useRecoilState } from "recoil";
import userAtom from "../atoms/userAtom";
import { useStages } from "../companies/hooks/use-stages";
import { Option } from "react-tailwindcss-select/dist/components/type";
import { useMondaySyncData } from "../companies/hooks/use-monday-sync-data";

export const Section = ({
    children,
    className,
    style,
}: {
    children: ReactNode;
    className?: string;
    style?: HTMLAttributes<HTMLDivElement>["style"];
}) => {
    return (
        <div
            style={style}
            className={`bg-gradient-to-br to-white from-slate-100 dark:from-slate-900 dark:to-gray-800 border-2 border-white rounded-xl dark:bg-gray-800 dark:border-gray-700 dark:shadow-inner shadow p-5 ${
                className || ""
            }`}
        >
            {children}
        </div>
    );
};

export const Stat = ({ title, value }: { title: ReactNode; value: ReactNode }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div className="text-sm font-medium text-gray-400">{title}</div>
            <div className="text-2xl font-semibold text-gray-500 dark:text-green-500">{value}</div>
        </div>
    );
};

const TableSection = ({
    title,
    tableContent,
    aggregatedAmount,
    statusOptions,
    filteredStatuses,
    setFilteredStatuses,
}: {
    title: string;
    tableContent: any;
    aggregatedAmount?: number;
    statusOptions?: Option[];
    filteredStatuses?: Option[];
    setFilteredStatuses?: (v: string) => void;
}) => {
    const [isTableHidden, setIsTableHidden] = useState(false);
    return (
        <>
            <div className="flex justify-between">
                <div className="flex gap-4 items-center">
                    {isTableHidden ? (
                        <ChevronUpIcon
                            className="w-5 cursor-pointer"
                            aria-hidden="true"
                            onClick={() => setIsTableHidden(!isTableHidden)}
                        />
                    ) : (
                        <ChevronDownIcon
                            className="w-5 cursor-pointer"
                            aria-hidden="true"
                            onClick={() => setIsTableHidden(!isTableHidden)}
                        />
                    )}
                    <h2>{title}</h2>
                    <div className="bg-white text-gray-900 rounded-md text-xs px-2 text-center">
                        <Currency amount={aggregatedAmount || 0} />
                    </div>
                </div>
                {statusOptions && setFilteredStatuses && filteredStatuses && (
                    <div className="w-56">
                        <Select
                            label="Progress status"
                            isMultiple
                            options={statusOptions}
                            value={
                                filteredStatuses && filteredStatuses.length > 0
                                    ? filteredStatuses[filteredStatuses.length - 1].value
                                    : null
                            }
                            onChange={(value) => setFilteredStatuses(value as string)}
                        />
                    </div>
                )}
            </div>
            <div className="max-h-96 overflow-y-scroll">
                <Table className={`${isTableHidden ? "hidden" : ""} mb-3`}>{tableContent}</Table>
            </div>
        </>
    );
};

const StageTableSection = ({
    title,
    rows,
    aggregatedAmount,
}: {
    title: string;
    rows: any;
    aggregatedAmount: number;
}) => {
    const navigate = useNavigate();
    const [columnToSort, setColumnToSort] = useState<string>();
    const [sortDirection, setSortDirection] = useState(false);
    const [filteredStatuses, setFilteredStatuses] = useState<Option[]>([]);
    const statuses = useMemo(() => filteredStatuses.map((s) => s.value), [filteredStatuses]);

    const sortedRows = useMemo(() => {
        const filteredResult = rows.filter((row: any) => {
            return statuses.length > 0 && statuses[0] !== null
                ? statuses[0].includes(row.processflowProgress?.status)
                : true;
        });
        const sortedResult = orderBy(
            filteredResult,
            (row) => {
                switch (columnToSort) {
                    case "Company":
                        return row.company?.name;
                    case "Owner":
                        return [row.ownedByUser?.firstName, row.ownedByUser?.lastName].join(" ");
                    case "Created at":
                        return row.company?.createdAt;
                    case "Progress status":
                        return row.processflowProgress?.status;
                    case "Estimated deal value":
                        return row.dealValue;
                    case "Estimated commission":
                        return row.estimatedCommission;
                }
            },
            sortDirection ? "asc" : "desc",
        );

        return sortedResult;
    }, [columnToSort, rows, sortDirection, statuses]);

    const SortAsc = ({ column }: { column: string }) => {
        return (
            <ChevronDownIcon
                className="w-3 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                    if (columnToSort === column) {
                    } else {
                        setColumnToSort(column);
                    }
                    setSortDirection(!sortDirection);
                }}
            />
        );
    };

    const SortDesc = ({ column }: { column: string }) => {
        return (
            <ChevronUpIcon
                className="w-3 cursor-pointer"
                aria-hidden="true"
                onClick={() => {
                    if (columnToSort === column) {
                    } else {
                        setColumnToSort(column);
                    }
                    setSortDirection(!sortDirection);
                }}
            />
        );
    };

    return (
        <TableSection
            title={title}
            aggregatedAmount={aggregatedAmount}
            statusOptions={[...new Set(rows.map((row: any) => row.processflowProgress?.status))].map((o) => ({
                value: o as string,
                label: o as string,
            }))}
            filteredStatuses={filteredStatuses}
            setFilteredStatuses={(s: string) => setFilteredStatuses([{ value: s, label: s }])}
            tableContent={
                <>
                    <THead sticky>
                        <Tr>
                            <Th>
                                <div className="flex gap-3">
                                    Company
                                    {columnToSort === "Company" && sortDirection ? (
                                        <SortAsc column="Company" />
                                    ) : (
                                        <SortDesc column="Company" />
                                    )}
                                </div>
                            </Th>
                            <Th>
                                <div className="flex gap-3">
                                    Created at
                                    {columnToSort === "Created at" && sortDirection ? (
                                        <SortAsc column="Created at" />
                                    ) : (
                                        <SortDesc column="Created at" />
                                    )}
                                </div>
                            </Th>
                            <Th>
                                <div className="flex gap-3">
                                    Owner
                                    {sortDirection && columnToSort === "Owner" ? (
                                        <SortAsc column="Owner" />
                                    ) : (
                                        <SortDesc column="Owner" />
                                    )}
                                </div>
                            </Th>
                            <Th>
                                <div className="flex gap-3">
                                    Progress status
                                    {columnToSort === "Progress status" && sortDirection ? (
                                        <SortAsc column="Progress status" />
                                    ) : (
                                        <SortDesc column="Progress status" />
                                    )}
                                </div>
                            </Th>
                            <Th>
                                <div className="flex gap-3">
                                    Estimated deal value
                                    {columnToSort === "Estimated deal value" && sortDirection ? (
                                        <SortAsc column="Estimated deal value" />
                                    ) : (
                                        <SortDesc column="Estimated deal value" />
                                    )}
                                </div>
                            </Th>
                            <Th>
                                <div className="flex gap-3">
                                    Estimated commission
                                    {columnToSort === "Estimated commission" && sortDirection ? (
                                        <SortAsc column="Estimated commission" />
                                    ) : (
                                        <SortDesc column="Estimated commission" />
                                    )}
                                </div>
                            </Th>
                            <Th />
                        </Tr>
                    </THead>
                    <TBody>
                        {sortedRows.length === 0 && (
                            <Tr>
                                <Td colSpan={7} style={{ textAlign: "center" }}>
                                    No deals
                                </Td>
                            </Tr>
                        )}
                        {sortedRows.length > 0 &&
                            sortedRows.map((row: any) => {
                                return (
                                    <Tr key={row.company.id}>
                                        <Td>{row.company?.name || "-"}</Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <div className="flex flex-col">
                                                {row.company?.createdAt?.toDateString()}
                                                <span style={{ color: "gray" }}>
                                                    {row.company?.createdAt
                                                        ? timeAgo(row.company.createdAt).toLocaleString()
                                                        : "-"}
                                                </span>
                                            </div>
                                        </Td>
                                        <Td>
                                            {[row.ownedByUser?.firstName, row.ownedByUser?.lastName].join(" ") || "-"}
                                        </Td>
                                        <Td>{row.processflowProgress?.status || "-"}</Td>
                                        <Td>
                                            <Currency amount={row.dealValue} />
                                        </Td>
                                        <Td>
                                            <Currency amount={row.estimatedCommission} />
                                        </Td>
                                        <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                            {row.company?.id && (
                                                <ButtonNeoGen
                                                    size="sm"
                                                    onClick={() => {
                                                        navigate(`/companies/${row.company?.id}`);
                                                    }}
                                                >
                                                    Open company
                                                </ButtonNeoGen>
                                            )}
                                        </Td>
                                    </Tr>
                                );
                            })}
                    </TBody>
                </>
            }
        />
    );
};

export const AffiliateBillingPage = () => {
    const now = useMemo(() => new Date(), []);
    const authToken = getAuthTokenNoThrow() || "no-auth-token";
    const [user] = useRecoilState(userAtom);
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParamUserId = useMemo(() => new URLSearchParams(search), [search]);
    const userId = queryParamUserId.get("userId");
    const [affiliateUserId, setAffiliateUserId] = useState(userId);

    useEffect(() => {
        if (user && user.id) {
            setAffiliateUserId(user.id);
        }
    }, [user]);

    const userCompanies = useUserCompanies({
        authToken,
        filters: { where: { roleGroupId: 7, ercUserId: affiliateUserId } },
    });

    const companiesQuery = useCompanies({
        authToken,
        filters: { where: { id: { inq: (userCompanies.data || []).map((uc) => uc.companyId) } } },
    });
    const companies = useMemo(() => companiesQuery.data || [], [companiesQuery.data]);

    const affiliateUsersQuery = useQuery(["affiliates", { authToken }], async () => {
        const users = await getUsers({
            authToken,
            filters: {
                where: {
                    role: "Affiliate",
                },
            },
        });
        return users;
    });

    const progressesQuery = useProcessflowProgresses({
        authToken,
        filters: { userIds: companies.map((c) => c.ownedById), group: 7 },
    });
    const processflowProgresses = useMemo(() => progressesQuery.data || [], [progressesQuery.data]);

    const stagesQuery = useStages({ authToken });
    const stages = useMemo(() => stagesQuery.data || [], [stagesQuery.data]);

    const withCpaStage = stages.find((s) => s.name === "With CPA");
    const withCpaStages = useMemo(
        () => [withCpaStage, ...stages.filter((s) => s.parent === withCpaStage?.id)],
        [stages, withCpaStage],
    );

    const withIrsStage = stages.find((s) => s.name === "With IRS");
    const withIrsStages = useMemo(
        () => [withIrsStage, ...stages.filter((s) => s.parent === withIrsStage?.id)],
        [stages, withIrsStage],
    );

    const inDocCollectionStage = stages.find((s) => s.name === "In Doc Collection");
    const inDocCollectionStages = useMemo(
        () => [inDocCollectionStage, ...stages.filter((s) => s.parent === inDocCollectionStage?.id)],
        [stages, inDocCollectionStage],
    );

    const salesStage = stages.find((s) => s.name === "Sales");
    const salesStages = useMemo(
        () => [salesStage, ...stages.filter((s) => s.parent === salesStage?.id)],
        [salesStage, stages],
    );

    const finalClearErcStage = stages.find((s) => s.name === "Final Clear Erc Stage");
    const finalClearErcStages = useMemo(
        () => [finalClearErcStage, ...stages.filter((s) => s.parent === finalClearErcStage?.id)],
        [finalClearErcStage, stages],
    );

    const sentToTaxAttorneyStage = stages.find((s) => s.name === "Sent To Tax Attoney");
    const sentToTaxAttorneyStages = useMemo(
        () => [sentToTaxAttorneyStage, ...stages.filter((s) => s.parent === sentToTaxAttorneyStage?.id)],
        [sentToTaxAttorneyStage, stages],
    );

    const rows = useMemo(
        () =>
            companies.map((c) => {
                const processflowProgress = processflowProgresses.find((p) => p.userId === c.ownedById);
                const dealValue = processflowProgress?.data
                    ? getEstimatedPayoutFromData({
                          data: getDataFromProgressData(processflowProgress.data as any),
                      })
                    : 0;
                const estimatedCommission = dealValue * affiliateCommissionMultiplier;
                const progressPercentage = Math.round((processflowProgress?.data?.percentageComplete || 0) * 100);
                return {
                    ownedByUser: c.ownedBy,
                    processflowProgress: processflowProgress,
                    progressPercentage,
                    lastActive: subDays(now, 2),
                    dealValue,
                    estimatedCommission,
                    company: c,
                };
            }),
        [companies, now, processflowProgresses],
    );

    const dealsValueInProgress = useMemo(() => sumBy(rows, (row) => row.dealValue || 0), [rows]);
    const expectedCommission = useMemo(() => sumBy(rows, (row) => row.estimatedCommission || 0), [rows]);
    const withCpaStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? withCpaStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, withCpaStages],
    );

    const withIrsStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? withIrsStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, withIrsStages],
    );

    const inDocCollectionStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? inDocCollectionStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, inDocCollectionStages],
    );

    const salesStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? salesStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, salesStages],
    );

    const finalClearErcStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? finalClearErcStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, finalClearErcStages],
    );

    const sentToTaxAttorneyStageTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? sentToTaxAttorneyStages.find((s) => s?.name === row.processflowProgress?.status)
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows, sentToTaxAttorneyStages],
    );

    const completedTotal = useMemo(
        () =>
            sumBy(rows, (row) =>
                row.processflowProgress?.status
                    ? row.processflowProgress?.status.toLowerCase().includes("deal completed") ||
                      row.processflowProgress?.status.toLowerCase().includes("deals completed") ||
                      row.progressPercentage === 100
                        ? row.dealValue
                        : 0
                    : 0,
            ),
        [rows],
    );

    const archivedTotal = useMemo(
        () => sumBy(rows, (row) => (row.company?.status === CompanyStatus.archived ? row.dealValue : 0)),
        [rows],
    );

    const otherRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? !withCpaStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !withIrsStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !inDocCollectionStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !salesStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !finalClearErcStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !sentToTaxAttorneyStages.map((s) => s?.name).includes(row.processflowProgress?.status) &&
                      !row.processflowProgress?.status.toLowerCase().includes("deal completed") &&
                      !row.processflowProgress?.status.toLowerCase().includes("deals completed") &&
                      row.progressPercentage !== 100
                    : undefined,
            ),
        [
            finalClearErcStages,
            inDocCollectionStages,
            rows,
            salesStages,
            sentToTaxAttorneyStages,
            withCpaStages,
            withIrsStages,
        ],
    );

    const otherTotal = useMemo(() => sumBy(otherRows, (row) => row.dealValue), [otherRows]);

    const withCpaRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? withCpaStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [rows, withCpaStages],
    );

    const withIrsRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? withIrsStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [rows, withIrsStages],
    );

    const inDocCollectionRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? inDocCollectionStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [inDocCollectionStages, rows],
    );

    const salesRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? salesStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [salesStages, rows],
    );

    const finalClearErcRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? finalClearErcStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [finalClearErcStages, rows],
    );

    const sentToTaxAttorneyRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? sentToTaxAttorneyStages.find((s) => s?.name === row.processflowProgress?.status)
                    : undefined,
            ),
        [sentToTaxAttorneyStages, rows],
    );

    const completedRows = useMemo(
        () =>
            rows.filter((row) =>
                row.processflowProgress?.status
                    ? row.processflowProgress?.status.toLowerCase().includes("deal completed") ||
                      row.processflowProgress?.status.toLowerCase().includes("deals completed")
                    : row.progressPercentage === 100,
            ),
        [rows],
    );

    // fetch time info for when completed deals transitioned to completed in monday data
    const mondayDataSyncQuery = useMondaySyncData({
        authToken,
        filters: {
            where: {
                clearErcId: {
                    inq: completedRows.map((r) => r.processflowProgress?.id) || [],
                },
                fieldId: 3,
            },
        },
    });
    const mondayDataSync = useMemo(() => mondayDataSyncQuery.data || [], [mondayDataSyncQuery.data]);

    const archivedRows = useMemo(() => rows.filter((row) => row.company?.status === CompanyStatus.archived), [rows]);

    const affiliateOptions = useMemo(
        () =>
            sortUsers((affiliateUsersQuery.data || []) as ClearERCUser[]).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliateUsersQuery.data],
    );

    const subAffiliateOptions = useMemo(
        () =>
            sortUsers(
                (affiliateUsersQuery.data || []).filter((a) => a.affiliateUserId === affiliateUserId) as ClearERCUser[],
            ).map((affiliate) => ({
                value: affiliate.id || "",
                label: `${[affiliate.firstName, affiliate.lastName].join(" ")} (${affiliate.email})`,
            })),
        [affiliateUserId, affiliateUsersQuery.data],
    );

    return (
        <div>
            <div style={{ display: "flex", flexDirection: "column", gap: 20 }}>
                <PageDescription title="Deals">
                    <div className="w-72">
                        <Select
                            label="Viewing deals for affiliate:"
                            options={affiliateOptions}
                            value={affiliateOptions.find((option) => option.value === affiliateUserId)?.value || null}
                            onChange={(value) => setAffiliateUserId(value as string)}
                        />
                        {subAffiliateOptions.length > 0 && (
                            <Select
                                label="Sub-affiliates:"
                                options={subAffiliateOptions}
                                value={
                                    affiliateOptions.find((option) => option.value === affiliateUserId)?.value || null
                                }
                                onChange={(value) => setAffiliateUserId(value as string)}
                            />
                        )}
                    </div>
                </PageDescription>

                <div
                    style={{ display: "flex", flexDirection: "row", gap: 20, flex: 1, justifyContent: "space-between" }}
                >
                    <Section
                        style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                            gridTemplateColumns: "1fr 1fr",
                            gridAutoRows: "70px",
                            rowGap: 0,
                            columnGap: 35,
                        }}
                        className="w-full"
                    >
                        <div style={{ gridRow: 1, gridColumn: "1 / 2" }}>
                            <Stat title="Deals in progress value" value={<Currency amount={dealsValueInProgress} />} />
                        </div>
                        <div style={{ gridRow: 1, gridColumn: "2 / 3" }}>
                            <Stat title="Expected total commission" value={<Currency amount={expectedCommission} />} />
                        </div>
                        <div style={{ gridRow: 2, gridColumn: "2 / 3" }}>
                            <Stat title="Outstanding commission" value={<Currency amount={0} />} />
                        </div>
                        <div style={{ gridRow: 3, gridColumn: "2 / 3" }}>
                            <Stat title="Paid commission" value={<Currency amount={0} />} />
                        </div>
                    </Section>
                </div>
                <StageTableSection title="With CPA" rows={withCpaRows} aggregatedAmount={withCpaStageTotal} />
                <StageTableSection title="With IRS" rows={withIrsRows} aggregatedAmount={withIrsStageTotal} />
                <StageTableSection
                    title="In Doc Collection"
                    rows={inDocCollectionRows}
                    aggregatedAmount={inDocCollectionStageTotal}
                />
                <StageTableSection title="Sales" rows={salesRows} aggregatedAmount={salesStageTotal} />
                <StageTableSection
                    title="Final Clear Erc Stage"
                    rows={finalClearErcRows}
                    aggregatedAmount={finalClearErcStageTotal}
                />
                <StageTableSection
                    title="Sent to Tax Attorney"
                    rows={sentToTaxAttorneyRows}
                    aggregatedAmount={sentToTaxAttorneyStageTotal}
                />

                <StageTableSection title="Other" rows={otherRows} aggregatedAmount={otherTotal} />

                <TableSection
                    title="Completed deals"
                    aggregatedAmount={completedTotal}
                    tableContent={
                        <>
                            <THead sticky>
                                <Tr>
                                    <Th>Company</Th>
                                    <Th>Completed at</Th>
                                    <Th>Progress status</Th>
                                    <Th>Estimated deal value</Th>
                                    <Th>Commission paid</Th>
                                    <Th>Commission outstanding</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {completedRows.length === 0 && (
                                    <Tr>
                                        <Td colSpan={7} style={{ textAlign: "center" }}>
                                            No completed deals
                                        </Td>
                                    </Tr>
                                )}
                                {completedRows.length > 0 &&
                                    completedRows.map((row) => {
                                        const completedAt = mondayDataSync.find(
                                            (d) => d.clearErcId === row.processflowProgress?.id?.toString(),
                                        )?.ercLastUpdated;
                                        return (
                                            <Tr key={row.company.id}>
                                                <Td>{row.company?.name || "-"}</Td>
                                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    <div className="flex flex-col">
                                                        {completedAt && new Date(completedAt).toDateString()}
                                                        <span style={{ color: "gray" }}>
                                                            {completedAt ? timeAgo(completedAt).toLocaleString() : "-"}
                                                        </span>
                                                    </div>
                                                </Td>
                                                <Td>{row.processflowProgress?.status}</Td>
                                                <Td>
                                                    <Currency amount={row.dealValue} />
                                                </Td>
                                                <Td>Coming soon</Td>
                                                <Td>Coming soon</Td>
                                                <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                    {row.company?.id && (
                                                        <ButtonNeoGen
                                                            size="sm"
                                                            onClick={() => {
                                                                navigate(`/companies/${row.company?.id}`);
                                                            }}
                                                        >
                                                            Open company
                                                        </ButtonNeoGen>
                                                    )}
                                                </Td>
                                            </Tr>
                                        );
                                    })}
                            </TBody>
                        </>
                    }
                />
                <TableSection
                    title="Archived deals"
                    aggregatedAmount={archivedTotal}
                    tableContent={
                        <>
                            <THead sticky>
                                <Tr>
                                    <Th>Company</Th>
                                    <Th>Owner</Th>
                                    <Th>Progress status</Th>
                                    <Th>Estimated deal value</Th>
                                    <Th>Estimated commission</Th>
                                    <Th />
                                </Tr>
                            </THead>
                            <TBody>
                                {archivedRows.length === 0 && (
                                    <Tr>
                                        <Td colSpan={6} style={{ textAlign: "center" }}>
                                            No archived deals
                                        </Td>
                                    </Tr>
                                )}
                                {archivedRows.length > 0 &&
                                    archivedRows.map((row) => (
                                        <Tr key={row.company.id}>
                                            <Td>{row.company?.name || "-"}</Td>
                                            <Td>
                                                {[row.ownedByUser?.firstName, row.ownedByUser?.lastName].join(" ") ||
                                                    "-"}
                                            </Td>
                                            <Td>{row.processflowProgress?.status}</Td>
                                            <Td>
                                                <Currency amount={row.dealValue} />
                                            </Td>
                                            <Td>
                                                <Currency amount={row.estimatedCommission} />
                                            </Td>
                                            <Td style={{ paddingTop: 0, paddingBottom: 0 }}>
                                                {row.company?.id && (
                                                    <ButtonNeoGen
                                                        size="sm"
                                                        onClick={() => {
                                                            navigate(`/companies/${row.company?.id}`);
                                                        }}
                                                    >
                                                        Open company
                                                    </ButtonNeoGen>
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                            </TBody>
                        </>
                    }
                />
            </div>
        </div>
    );
};
